class Datetime{

    constructor(value){

        if(value){
            this.value = new Date(value);
        }else{
            this.value = new Date();
        }


        this.week_days = new Array('sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday');
    }

    get(){
        return this.value;
    }
    set(value){
        this.value = value;
    }

    getWeekDay() {
        return this.week_days[this.value.getDay()];
    }
    getDay() {
        return ('0' + this.value.getDate() ).slice(-2);
    }
    getMonth() {
        
        return ('0' + ( this.value.getMonth() + 1 )).slice(-2);
    }
    getYear() {
        return this.value.getFullYear()
    }

    getDate(){
        return ( this.getYear() + '-' +  this.getMonth() + '-' +  this.getDay() )
    }

    
    getHours(){
        return ('0' + this.value.getHours() ).slice(-2);
    }
    getMinutes(){
        return ('0' + this.value.getMinutes() ).slice(-2);
    }
    getSeconds(){
        return ('0' + this.value.getSeconds() ).slice(-2);
    }
    getMillis(){
        return ('00' + this.value.getMilliseconds() ).slice(-3);
    }

    getTime(){
        return (  this.getHours() + ':' +  this.getMinutes() + ':' +  this.getSeconds() );
    }
    getTimeMillis(){
        return (  this.getHours() + ':' +  this.getMinutes() + ':' +  this.getSeconds() + '.' +  this.getMillis());
    }

    getDateTime(){
        return (  this.getDate() + ' ' +  this.getTime() );
    }
    getDateTimeMillis(){
        return (  this.getDate() + ' ' +  this.getTimeMillis() );
    }

    getUnixTimestamp(){
        return parseInt( (this.value.getTime().toString() / 1000).toString().split('.')[0] );
    }
    getUnixTimestampMillis(){
        return parseInt( this.value.getTime() )
    }
}

export default Datetime;